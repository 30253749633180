export default {
    '4g':
        'M9.7,8.7L8.3,8.7L8.3,13.7L5.7,13.7L5.7,8.7L4.3,8.7L4.3,15.2L8.3,15.2L8.3,18.2L9.7,18.2L9.7,15.2L11.7,15.2L11.7,13.7L9.7,13.7z ' +
        'M22,5.5C21.6,5.1,21.2,5,20.7,5h-0.1V2.8c0-0.4-0.4-0.8-0.8-0.8S19,2.4,19,2.8V5H3.3C2.8,5,2.4,5.1,2,5.5 C1.7,5.8,1.5,6.3,1.5,6.8v13.4c0,0.5,0.2,0.9,0.5,1.3C2.4,21.8,2.8,22,3.3,22h17.4c0.5,0,0.9-0.2,1.3-0.5c0.4-0.4,0.5-0.8,0.5-1.3 V6.8C22.5,6.3,22.3,5.8,22,5.5z' +
        'M21,20.2c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1H3.3c-0.1,0-0.1,0-0.2-0.1 C3,20.3,3,20.2,3,20.2V6.8c0-0.1,0-0.1,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.1h17.4c0.1,0,0.1,0,0.2,0.1C21,6.6,21,6.7,21,6.8V20.2z' +
        'M14.5,10.3c0.1-0.1,0.1-0.1,0.2-0.1h4.7c-0.1-0.4-0.2-0.8-0.6-1.1s-0.7-0.4-1.2-0.4h-3c-0.5,0-0.9,0.2-1.3,0.5 C13.1,9.6,13,10,13,10.5v5.9c0,0.5,0.2,0.9,0.5,1.3c0.4,0.4,0.8,0.5,1.3,0.5h2.9c0.5,0,0.9-0.2,1.3-0.5s0.5-0.8,0.5-1.3v-3.7h-2.8 v1.5H18v2.2c0,0.1,0,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.1h-2.9c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2v-5.9 C14.5,10.4,14.5,10.4,14.5,10.3z',

    'dc1':
        'M8.1,23.7v-2.6H4.9c-0.5,0-1-0.2-1.4-0.6s-0.6-0.8-0.6-1.4v-3.2H0.3V14h2.6v-4H0.3V8h2.6V4.7 c0-0.5,0.2-1,0.6-1.4s0.8-0.6,1.4-0.6h3.2V0.3H10v2.5h4V0.3h2v2.5h3.2c0.5,0,1,0.2,1.4,0.6s0.6,0.8,0.6,1.4V8h2.5v1.9h-2.5v4h2.5 v1.9h-2.5V19c0,0.5-0.2,1-0.6,1.4S19.8,21,19.2,21H16v2.6h-1.9V21h-4v2.6h-2V23.7z' +
        'M19.3,19.1V4.7H4.9v14.4 C4.9,19.1,19.3,19.1,19.3,19.1z' +
        'M13.2,7.3v8.5h-1.8V9.1h-0.9V7.3H13.2z',

    'dc2':
        'M8.1,23.7v-2.6H4.9c-0.5,0-1-0.2-1.4-0.6s-0.6-0.8-0.6-1.4v-3.2H0.3V14h2.6v-4H0.3V8h2.6V4.7 c0-0.5,0.2-1,0.6-1.4s0.8-0.6,1.4-0.6h3.2V0.3H10v2.5h4V0.3h2v2.5h3.2c0.5,0,1,0.2,1.4,0.6s0.6,0.8,0.6,1.4V8h2.5v1.9h-2.5v4h2.5 v1.9h-2.5V19c0,0.5-0.2,1-0.6,1.4S19.8,21,19.2,21H16v2.6h-1.9V21h-4v2.6h-2V23.7z' +
        'M19.3,19.1V4.7H4.9v14.4 C4.9,19.1,19.3,19.1,19.3,19.1z' +
        'M11.9,14h2.7v1.8H9.4v-1.6l3.2-3.9c0.1-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.5-0.3-0.6c-0.2-0.2-0.4-0.3-0.6-0.3 c-0.3,0-0.5,0.1-0.6,0.3C11.1,9.3,11,9.5,11,9.8H9.2C9.2,9,9.5,8.4,10,7.9c0.5-0.5,1.2-0.8,1.9-0.8c0.7,0,1.4,0.3,1.9,0.8 c0.5,0.5,0.8,1.2,0.8,1.9c0,0.6-0.2,1.2-0.6,1.7L11.9,14z',

    'dc10':
        'M8.1,23.7v-2.6H4.9c-0.5,0-1-0.2-1.4-0.6s-0.6-0.8-0.6-1.4v-3.2H0.3v-1.9h2.6v-4H0.3V8h2.6V4.7 c0-0.5,0.2-1,0.6-1.4s0.8-0.6,1.4-0.6h3.2V0.3h1.9v2.5h4V0.3H16v2.5h3.2c0.5,0,1,0.2,1.4,0.6s0.6,0.8,0.6,1.4V8h2.5v1.9h-2.5v4 h2.5v1.9h-2.5v3.2c0,0.5-0.2,1-0.6,1.4s-0.8,0.6-1.4,0.6H16v2.6h-1.9v-2.6h-4v2.6H8.1z' +
        'M19.3,19.1V4.7H4.9v14.4H19.3z' +
        'M9.6,7.3v8.5H7.8V9.1H6.9V7.3H9.6z' +
        'M16.8,10v3.1c0,0.8-0.3,1.5-0.9,2.1c-0.6,0.6-1.3,0.9-2.1,0.9s-1.5-0.3-2.1-0.9S11,13.9,11,13.1V10 c0-0.8,0.3-1.5,0.9-2.1s1.3-0.9,2.1-0.9s1.5,0.3,2.1,0.9C16.6,8.5,16.8,9.2,16.8,10z' +
        'M13.9,14.3c0.3,0,0.6-0.1,0.8-0.3 s0.3-0.5,0.3-0.8V10c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8v3.1 c0,0.3,0.1,0.6,0.3,0.8C13.3,14.1,13.6,14.3,13.9,14.3z',

    'input':
        'M16.6,16.9l-1.2-1.2l2.9-2.9H2.5v-1.7h15.8l-2.9-2.9l1.2-1.2l4.9,4.9L16.6,16.9z',

    'output':
        'M7.3,7.1l1.2,1.2l-2.9,2.9h15.8v1.7H5.7l2.9,2.9l-1.2,1.2L2.5,12L7.3,7.1z',

    'failover':
        'M 18,14 H 16 Q 16,12.525 14.925,11.5125 13.85,10.5 12.35,10.5 q -0.9,0 -1.675,0.4125 Q 9.9,11.325 9.4,12 H 11 v 2 H 6 V 9 h 2 v 1.55 Q 8.8,9.6 9.9125,9.05 11.025,8.5 12.35,8.5 q 2.375,0 4.0125,1.6 Q 18,11.7 18,14 Z' +
        'm -6,8 q 2.075,0 3.9,-0.7875 1.825,-0.7875 3.175,-2.1375 1.35,-1.35 2.1375,-3.175 Q 22,14.075 22,12 22,9.925 21.2125,8.1 20.425,6.275 19.075,4.925 17.725,3.575 15.9,2.7875 14.075,2 12,2 9.925,2 8.1,2.7875 6.275,3.575 4.925,4.925 3.575,6.275 2.7875,8.1 2,9.925 2,12 2,14.075 2.7875,15.9 3.575,17.725 4.925,19.075 6.275,20.425 8.1,21.2125 9.925,22 12,22 Z' +
        'm 0,-2 Q 8.65,20 6.325,17.675 4,15.35 4,12 4,8.65 6.325,6.325 8.65,4 12,4 15.35,4 17.675,6.325 20,8.65 20,12 20,15.35 17.675,17.675 15.35,20 12,20 Z' +
        'm 0,-8 z',
}